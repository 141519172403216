import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { getCurrentToken } from "../../services/cognito/cognitoAuth";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import ProjectContext from "../../ProjectContext";

function EditUser({ user, handleClose, triggerRefresh }) {
  const [projects, setProjects] = useState([]);
  const { roles } = useContext(ProjectContext);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    role: user.userGroups.join(","),
    phone: user.phone,
    project: user.projects.map((project) => project.id),
  });
  const roleOptions = [
    { value: "SuperAdmin", label: "Super Admin" },
    { value: "Reviewer", label: "Reviewer" },
    { value: "ProjectAdmin", label: "Project Admin" },
    { value: "Developer", label: "Developer" },
  ];
  const fetchData = async () => {
    try {
      const token = await getCurrentToken();

      if (!token) {
        console.error("No token found");
        return;
      }

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}project/list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            page: 1,
            size: 1000, // Fetch a large number of projects
            search: "",
            status: "",
            sortColumn: "name",
            sortOrder: "DESC",
          },
        }
      );

      const jsonData = response.data;

      if (Array.isArray(jsonData.content)) {
        setProjects(jsonData.content);
      } else {
        console.error("Invalid data format: content is not an array");
      }
    } catch (error) {
      console.error("Error fetching project data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleRoleChange = (selectedOption) => {
    let newFormData = {
      ...formData,
      role: selectedOption ? selectedOption.value : "",
    };
    // If the selected role is SuperAdmin, clear the project field
    if (selectedOption && selectedOption.value === "SuperAdmin") {
      newFormData.project = [];
    }

    setFormData(newFormData);
  };
  const handleProjectChange = (selectedOption) => {
    setFormData({
      ...formData,
      project: selectedOption
        ? selectedOption.map((option) => option.value)
        : [],
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let newErrors = {};

  // Check if first name is provided
  if (!formData.firstName) {
    newErrors.firstName = "First name is required";
  }

  // Check if last name is provided
  if (!formData.lastName) {
    newErrors.lastName = "Last name is required";
  }

  // Check if project is selected when role is not SuperAdmin
  if (formData.role !== "SuperAdmin" && formData.role !== "Developer" && formData.project.length === 0) {
    newErrors.project = "Project is required";
  }

  // If there are any errors, don't submit the form
  if (Object.keys(newErrors).length > 0) {
    setErrors(newErrors);
    return;
  }
    try {
      const token = await getCurrentToken();
      await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}user`,
        {
          id: user.id,
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          phone: formData.phone,
          projectIds: formData.project.map((projectId) => parseInt(projectId)),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Update User Role
      await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}user/groups`,
        {
          userId: user.id,
          groups: Array.isArray(formData.role)
            ? formData.role
            : [formData.role],
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      triggerRefresh(); // Refresh the user list
      fetchData(); // Refetch the projects
      handleClose(); // Close the modal after updating the user
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };
  const isProjectAdmin = roles.includes("ProjectAdmin");
  return (
    <>
      <Modal.Header>
        <Modal.Title>Edit User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label>First Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  disabled={isProjectAdmin}
                />
                {errors.firstName && <p className="text-danger">{errors.firstName}</p>}
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label>Last Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  disabled={isProjectAdmin}
                />
                {errors.lastName && <p className="text-danger">{errors.lastName}</p>}
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label>Email</label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  value={formData.email}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label>Phone</label>
                <input
                  type="text"
                  className="form-control"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  disabled={isProjectAdmin}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label>Role</label>
                <Select
                  options={roleOptions}
                  value={roleOptions.find(
                    (option) => formData.role === option.value
                  )}
                  onChange={handleRoleChange}
                  name="roles"
                  isDisabled={isProjectAdmin}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label>Project</label>
                <Select
                  options={projects.map((project) => ({
                    value: project.id,
                    label: project.name,
                  }))}
                  value={formData.project
                    .map((projectId) => {
                      const project = projects.find(
                        (project) => project.id === projectId
                      );
                      return project
                        ? { value: projectId, label: project.name }
                        : null;
                    })
                    .filter(Boolean)}
                  onChange={handleProjectChange}
                  name="project"
                  isMulti 
                  isDisabled={formData.role === "SuperAdmin" || formData.role === "Developer"}
                />
                {errors.project && <p className="text-danger">{errors.project}</p>}
              </div>
            </div>
          </div>
          <div className="mb-3 text-end">
            <button
              type="button"
              className="btn btn-secondary me-2"
              onClick={handleClose}
            >
              Close
            </button>
            <button type="submit" className="btn btn-primary">
              Save Changes
            </button>
          </div>
        </form>
      </Modal.Body>
    </>
  );
}

export default EditUser;

import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { getCurrentToken } from "../../services/cognito/cognitoAuth";
import {
  Download,
  Search,
  EyeFill,
  ChevronDown,
  ChevronUp,
  ThreeDotsVertical,
  PersonFillX,
  JournalText,
  ViewList,
} from "react-bootstrap-icons";
import ProjectContext from "../../ProjectContext";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Modal, Collapse, Dropdown } from "react-bootstrap";
import Select from "react-select";
import Pagination from "../Pagination";
import { formatDate } from "../../utils";
import LogsTable from "../Logs/LogsTable";

function ProcessedList({
  initialStatus,
  onClear,
  refresh,
  lastViewedFileId,
  updateInitialStatus,
  handleViewList,
}) {
  const [files, setFiles] = useState([]);
  const [totalPageSize, setTotalPageSize] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const pageSize = 10;
  const {
    projectId,
    userId,
    refreshFiles,
    roles,
  } = useContext(ProjectContext);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState([]);
  const [selectedReviewer, setSelectedReviewer] = useState([]);
  const [users, setUsers] = useState([]);
  const [count, setCount] = useState(0);
  const [error, setError] = useState("");
  const [userError, setUserError] = useState("");
  const [success, setSuccess] = useState("");
  const [userPageSize, setUserPageSize] = useState();
  const [selectedFiles, setSelectedFiles] = useState({});
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [showFilters, setShowFilters] = useState(true);
  const [applyFilters, setApplyFilters] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [totalListCount, setTotalListCount] = useState(0);
  const location = useLocation();
  const [showActionModal, setShowActionModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [message, setMessage] = useState("");
  const [selectedStatusList, setSelectedStatusList] = useState(() => {
    const storedStatus = localStorage.getItem("selectedStatusList");
    if (storedStatus) {
      // Parse the stored statuses and convert them into the format needed by Select
      return JSON.parse(storedStatus).map((status) => ({
        value: status,
        label: status,
      }));
    }
    return [];
  });
  const [currentPage, setCurrentPage] = useState(() => {
    const savedPage = JSON.parse(localStorage.getItem('listCurrentPage'));
    return savedPage || 1;
  });
  // Listen for tab refresh and update the selectedStatusList
  useEffect(() => {
    const storedStatus = localStorage.getItem("selectedStatusList");
    if (storedStatus) {
      setSelectedStatusList(
        JSON.parse(storedStatus).map((status) => ({
          value: status,
          label: status,
        }))
      );
      setApplyFilters(true); // Trigger the filtering process
    }
  }, [refresh]);

  // Store selectedStatusList in local storage when it changes
  useEffect(() => {
    localStorage.setItem(
      "selectedStatusList",
      JSON.stringify(selectedStatusList.map((option) => option.value))
    );
    localStorage.setItem('listCurrentPage', JSON.stringify(currentPage));
  }, [selectedStatusList, currentPage]);

  // Trigger filtering on initial load if selectedStatusList is not empty
  useEffect(() => {
    if (selectedStatusList.length > 0) {
      setApplyFilters(true); // Trigger the fetch on initial load
    }
  }, []);
  // Fetch files when dependencies change
  useEffect(() => {
    if (
      (projectId || (roles.includes("Developer") && userId)) &&
      (applyFilters || currentPage || refresh)
    ) {
      fetchFiles();
      if (applyFilters) {
        setApplyFilters(false);
      }
    }
  }, [
    currentPage,
    searchQuery,
    projectId,
    refresh,
    applyFilters,
    refreshFiles,
    roles,
    userId,
  ]);
  const fetchFiles = async () => {
    setIsLoading(true); // Set loading to true
    try {
      const token = await getCurrentToken();

      if (!token) {
        console.error("No token found");
        return;
      }
      const formattedStartDate = startDate
        ? new Date(startDate).toISOString()
        : null;
      let endDateObj = endDate ? new Date(endDate) : null;
      if (endDateObj) {
        endDateObj.setUTCDate(endDateObj.getUTCDate() + 1);
        endDateObj.setUTCHours(0, 0, 0, 0);
      }
      const formattedEndDate = endDateObj ? endDateObj.toISOString() : null;
      const statusString = selectedStatusList
        .map((option) => option.value)
        .join(",");
      let params = {
        page: currentPage,
        size: pageSize,
        search: searchQuery,
        sortColumn: "startedAt",
        sortOrder: "DESC",
        status: statusString?.length > 0 ? statusString : "",
        fromDate: formattedStartDate,
        toDate: formattedEndDate,
        reviewerId: selectedUser?.value || null,
      };

      if (!roles.includes("Developer")) {
        params = {
          ...params,
          projectId: projectId,
        };
      }

      if (roles.includes("Developer")) {
        params = {
          ...params,
          userId: userId,
        };
      }
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}fileLog/list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: params,
        }
      );

      const jsonData = response.data;
      const pageInfo = response.data.pageInfo;
      let adjustedTotalPageSize = pageInfo.totalPageSize;
      if (pageInfo.totalElements > 10 && pageInfo.totalElements % 10 !== 0) {
        adjustedTotalPageSize += 1;
      }
      setTotalPageSize(adjustedTotalPageSize);
      const totalList = response.data.pageInfo.totalElements;
      setTotalListCount(totalList);
      if (Array.isArray(jsonData.content)) {
        setFiles(jsonData.content);
      } else {
        console.error("Invalid data format: content is not an array");
      }
    } catch (error) {
      console.error("Error fetching file data:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getStatusClassName = (status) => {
    return status === "UPLOADED"
      ? "badge text-bg-secondary"
      : status === "FAILED"
      ? "badge text-bg-danger"
      : status === "PROCESSING"
      ? "badge text-bg-warning"
      : status === "ASSIGNED"
      ? "badge text-bg-primary"
      : status === "PROCESSED"
      ? "badge text-bg-info"
      : "badge text-bg-success";
  };

  const handleViewFile = async (
    filePathJson,
    processedFileURL,
    id,
    currentPage
  ) => {
    navigate("/file-viewer", {
      state: { filePathJson, processedFileURL, id, currentPage },
    });

    // try {
    //   const token = await getCurrentToken();

    //   if (!token) {
    //     console.error("No token found");
    //     return;
    //   }
    //   const stausResponse = await axios.post(
    //     `${process.env.REACT_APP_BACKEND_URL}file-upload/save-action-log
    //     `,
    //     {
    //       fileId: id,
    //       userId: userId,
    //       status: "VIEWED",
    //     },
    //     {
    //       headers: {
    //         Authorization: `Bearer ${token}`,
    //       },
    //     }
    //   );
    // } catch (error) {
    //   console.error("Error viewing file:", error);
    // }
  };

  // const handleFileSelect = (fileId) => {
  //   setSelectedFiles((prevSelectedFiles) => {
  //     if (prevSelectedFiles.includes(fileId)) {
  //       return prevSelectedFiles.filter((id) => id !== fileId);
  //     } else {
  //       return [...prevSelectedFiles, fileId];
  //     }
  //   });
  // };
  const fetchUsers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}user/list`,
        {
          headers: {
            Authorization: `Bearer ${await getCurrentToken()}`,
          },
          params: {
            page: 1,
            size: "",
            search: "",
            status: "ACTIVE",
            sortColumn: "id",
            sortOrder: "DESC",
            group: "Reviewer",
            projectId: projectId,
          },
        }
      );
      setUserPageSize(response.data.pageInfo.totalElements);
      if (response.status === 200) {
        setUsers(
          response.data.content.map((user) => ({
            value: user.id,
            label:
              user.firstName +
              " " +
              user.lastName +
              " (" +
              user.userGroups +
              ")",
          }))
        );
      } else {
        console.error("Error fetching users:", response);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };
  const handleShow = () => {
    setShowModal(true);
    //fetchUsers();
  };
  useEffect(() => {
    if (showFilters) {
      fetchUsers();
    }
  }, [showFilters]);
  const handleClose = () => {
    setShowModal(false);
  };
  const handleReviewerSelect = (selectedReviewer) => {
    setSelectedReviewer(selectedReviewer);
    setUserError(''); // Clear the error message
  };
  const assignFiles = async () => {
    if (totalSelectedFiles === 0 && !count) {
      setError("Count is required");
      return;
    }
    if (!selectedReviewer || !selectedReviewer.value) {
      setUserError("Reviewer is required");
      return;
    }
    try {
      const token = await getCurrentToken();

      if (!token) {
        console.error("No token found");
        return;
      }

      let payload = {
        reviewerId: selectedReviewer.value,
        adminId: userId,
        projectId: projectId,
      };

      // If files are selected, add them to the payload, otherwise add the count
      if (totalSelectedFiles > 0) {
        payload.fileIds = Object.values(selectedFiles).flat();
      } else {
        payload.count = count;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}file-upload/assign-files`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setSuccess("Files assigned successfully");
        setTimeout(() => {
          setSuccess(null);
        }, 2000);
        setError("");
        setUserError("");
        setCount();
        setSelectedFiles([]);
        setSelectedReviewer(null);
        setShowModal(false);
        fetchFiles();
      } else {
        console.error("Error assigning files:", response);
      }
    } catch (error) {
      console.error("Error assigning files:", error);
    }
  };

  const statuses = [
    "UPLOADED",
    "FAILED",
    "PROCESSING",
    "PROCESSED",
    "ASSIGNED",
    "ACTIVE",
    "RESOLVED",
    "ISSUE",
    "NOISSUE",
  ];
  const handleFilterApply = () => {
    setApplyFilters(true);
    setCurrentPage(1);
    fetchFiles();
  };
  const handleFilterClear = () => {
    setStartDate("");
    setEndDate("");
    setSelectedUser("");
    setApplyFilters(true);
    setSelectedStatusList([]);
    onClear();
    fetchFiles();
  };
  const handleSelectAll = () => {
    if (selectAll[currentPage]) {
      setSelectedFiles({ ...selectedFiles, [currentPage]: [] });
      setSelectAll({ ...selectAll, [currentPage]: false });
    } else {
      setSelectedFiles({
        ...selectedFiles,
        [currentPage]: files
          .filter(
            (file) => file.status === "PROCESSED" && file.filePathJson != null
          )
          .map((file) => file.id),
      });
      setSelectAll({ ...selectAll, [currentPage]: true });
    }
  };

  const handleFileSelect = (id) => {
    if (selectedFiles[currentPage]?.includes(id)) {
      setSelectedFiles({
        ...selectedFiles,
        [currentPage]: selectedFiles[currentPage].filter(
          (fileId) => fileId !== id
        ),
      });
    } else {
      setSelectedFiles({
        ...selectedFiles,
        [currentPage]: [...(selectedFiles[currentPage] || []), id],
      });
    }
  };
  const totalSelectedFiles = Object.values(selectedFiles).reduce(
    (total, files) => total + files.length,
    0
  );
  const handleViewLogs = async (id, currentPage) => {
    navigate("/action-log", {
      state: { id, currentPage },
    });
  };

  const handleCloseAction = () => setShowActionModal(false);
  const unassignUser = async (fileId, reviewerId) => {
    try {
      const token = await getCurrentToken();

      if (!token) {
        console.error("No token found");
        return;
      }
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}file-upload/unassign-user`,
        {
          reviewerId: reviewerId,
          fileId: fileId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // handle the response here
      setMessage("Reviewer unassigned successfully");
      setTimeout(() => {
        setMessage("");
      }, 3000);
      fetchFiles();
    } catch (error) {
      // handle the error here
      console.error(error);
    }
  };
  // const handleViewList = async (id, currentPage) => {
  //   navigate("/issues", {
  //     state: { id, currentPage },
  //   });
  // };
  return (
    <div className="container-fluid flex-grow-1">
      <div className="d-flex align-items-center justify-content-end pb-3">
        {roles.includes("ProjectAdmin") && (
          <button
            className="btn btn-sm btn-outline-primary px-3 me-2"
            onClick={handleShow}
          >
            Assign Files
          </button>
        )}
        <div>
          <div className="input-group flex-nowrap">
            <span
              className="input-group-text bg-transparent"
              id="addon-wrapping"
            >
              <Search />
            </span>
            <input
              type="text"
              className="form-control"
              placeholder="Search List"
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
                setCurrentPage(1);
              }}
            />
          </div>
        </div>
      </div>
      <div className="text-end">
        <button
          onClick={() => setShowFilters(!showFilters)}
          aria-controls="filter-collapse"
          aria-expanded={showFilters}
          className="btn mb-2 border-0 px-2 fw-bold text-primary"
        >
          {showFilters ? (
            <>
              <ChevronUp /> Hide
            </>
          ) : (
            <>
              <ChevronDown /> Show
            </>
          )}{" "}
          Filters
        </button>
      </div>
      <Collapse in={showFilters}>
        <div id="filter-collapse" className="border rounded-3 p-4 mb-3">
          <div className="row">
            <div className="col-md-2">
              <div className="mb-3">
                <label>Start Date</label>
                <input
                  type="date"
                  className="form-control"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="mb-3">
                <label>End Date</label>
                <input
                  type="date"
                  className="form-control"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
            </div>
            {roles.includes("ProjectAdmin") && (
              <div className="col-md-4">
                <div className="mb-3">
                  <label>Assigned to</label>
                  <Select
                    name="users"
                    options={users}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select Reviewer"
                    onChange={setSelectedUser}
                    value={selectedUser}
                    isClearable
                  />
                </div>
              </div>
            )}
            <div className="col-md-4">
              <div className="mb-3">
                <label>Status</label>
                <Select
                  options={statuses.map((status) => ({
                    value: status,
                    label: status,
                  }))}
                  onChange={(selectedOptions) =>
                    setSelectedStatusList(selectedOptions || [])
                  }
                  value={selectedStatusList}
                  placeholder="Select Status"
                  className="w-100"
                  isMulti
                />
              </div>
            </div>
          </div>

          <div className="text-end">
            <button
              onClick={handleFilterClear}
              className="btn btn-secondary me-2"
            >
              Clear
            </button>
            <button onClick={handleFilterApply} className="btn btn-primary">
              Filter
            </button>
          </div>
        </div>
      </Collapse>
      {success && <p className="alert alert-success">{success}</p>}
      {message && <p className="alert alert-success">{message}</p>}
      <div className="table-responsive border rounded p-2">
        {files.length > 0 ? (
          <>
            {isLoading ? (
              <p>Loading...</p>
            ) : (
              <table className="table table-borderless vertical-align-middle">
                <thead>
                  <tr>
                    <th>
                      <input
                        type="checkbox"
                        checked={selectAll[currentPage] || false}
                        onChange={handleSelectAll}
                      />
                    </th>
                    <th>Id</th>
                    <th>File Name</th>
                    <th>Date</th>
                    <th>Assigned to</th>
                    {/* <th>Started At</th>
                    <th>Completed At</th> */}
                    <th>Timestamp</th>
                    <th>Status</th>
                    <th>Issue Count</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {files.map((file) => (
                    <tr
                      key={file.id}
                      className={
                        lastViewedFileId === file.id ? "active-row" : ""
                      }
                    >
                      <td>
                        <input
                          type="checkbox"
                          checked={
                            selectedFiles[currentPage]?.includes(file.id) ||
                            false
                          }
                          onChange={() => handleFileSelect(file.id)}
                          disabled={
                            file.status !== "PROCESSED" ||
                            file.filePathJson == null
                          }
                        />
                      </td>
                      <td>{file.id}</td>
                      <td width="35%">{file.name}</td>
                      <td>{file.dateOfFolder}</td>
                      <td>
                        {file.reviewerFirstName && file.reviewerLastName
                          ? `${file.reviewerFirstName} ${file.reviewerLastName}`
                          : "-"}
                      </td>
                      <td>
                        {file.timeStamp ? formatDate(file.timeStamp) : ""}
                      </td>
                      <td>
                        <span className={getStatusClassName(file.status)}>
                          {file.status}
                        </span>
                      </td>
                      <td>
                        {file.issueCount === 0 ? '-' : file.issueCount}
                      </td>
                      <td>
                        {file.status !== "UPLOADED" &&
                          file.status !== "FAILED" &&
                          file.status !== "PROCESSING" && (
                            <Dropdown align="end">
                              <Dropdown.Toggle
                                variant="outline-primary"
                                className="px-3 no-arrow btn-sm"
                              >
                                <ThreeDotsVertical size={18} />
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                {file.filePathJson &&
                                  file.filePathJson !== "null" && (
                                    <Dropdown.Item
                                      onClick={() =>
                                        handleViewFile(
                                          file.filePathJson,
                                          file.processedFileURL,
                                          file.id,
                                          currentPage
                                        )
                                      }
                                    >
                                      <EyeFill className="me-2" /> View
                                    </Dropdown.Item>
                                  )}
                                {roles.includes("ProjectAdmin") && (
                                  <Dropdown.Item
                                    className="border-top"
                                    onClick={() =>
                                      handleViewLogs(file.id, currentPage)
                                    }
                                  >
                                    <JournalText className="me-2" /> Action Log
                                  </Dropdown.Item>
                                )}
                                {(file.status === "ISSUE" ||
                                  file.status === "RESOLVED") && (
                                  <Dropdown.Item
                                    className="border-top"
                                    onClick={() =>
                                      handleViewList(file.id, currentPage)
                                    }
                                  >
                                    <ViewList className="me-2" /> Issue List
                                  </Dropdown.Item>
                                )}
                                {file.status === "ASSIGNED" && (
                                  <Dropdown.Item
                                    className="border-top"
                                    onClick={() =>
                                      unassignUser(file.id, file.reviewerId)
                                    }
                                  >
                                    <PersonFillX className="me-2" /> Unassign
                                  </Dropdown.Item>
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          )}
                        {/* {file.status !== "RESOLVED" &&
                          file.filePathJson &&
                          file.filePathJson !== "null" && (
                            <button
                              onClick={() =>
                                handleViewFile(
                                  file.filePathJson,
                                  file.processedFileURL,
                                  file.id,
                                  currentPage
                                )
                              }
                              className="btn btn-sm btn-success px-3"
                            >
                              <EyeFill size={18} />
                            </button>
                          )} */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            <Pagination
              currentPage={currentPage}
              totalPageSize={totalPageSize}
              onPageChange={handlePageChange}
              totalListCount={totalListCount}
              pageSize={pageSize}
            />
            <Modal show={showActionModal} onHide={handleCloseAction} size="xl">
              <Modal.Header closeButton>
                <Modal.Title>Action Log</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <LogsTable id={selectedId} />
              </Modal.Body>
            </Modal>
            <Modal show={showModal} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Assign Document</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="mb-3">
                  <label className="form-label">Select Reviewer</label>
                  <Select
                    name="users"
                    options={users}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={handleReviewerSelect}
                  />
                  {userError && <p className="text-danger">{userError}</p>}
                </div>
                {totalSelectedFiles === 0 && (
                  <div className="mb-3">
                    <label className="form-label">Number of Files</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter number of files"
                      min="1"
                      value={count}
                      onChange={(e) => {
                        setCount(e.target.value);
                        setError(''); 
                      }}
                    />
                    {error && <p className="text-danger">{error}</p>}
                  </div>
                )}
                <div>
                  <button
                    className="btn btn-primary mt-3"
                    onClick={assignFiles}
                  >
                    Assign
                  </button>
                  <button
                    className="btn btn-secondary mt-3 ms-2"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                </div>
              </Modal.Body>
            </Modal>
          </>
        ) : (
          <p className="text-center fs-5 mt-4">No files in current project</p>
        )}
      </div>
    </div>
  );
}

export default ProcessedList;

import React, { useState, useEffect } from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  Navigate,
} from "react-router-dom";
import { isLoggedIn, fetchUserRoles } from "./services/cognito/cognitoAuth";
import AuthPage from "./pages/AuthPage";
import AuthContext from "./components/auth/AuthContext";
import ChangePassword from "./components/auth/ChangePassword";
import AuthenticatedRoutes from "./components/AuthenticatedRoutes";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import ProjectContext from "./ProjectContext";

function App() {
  const [authenticated, setAuthenticated] = useState(false);
  const [canRender, setCanRender] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [projectId, setProjectId] = useState(null);
  const [roles, setRoles] = useState([]);
  const [projectName, setProjectName] = useState(null);
  const [userId, setUserId] = useState(null);
  // const [isProcessing, setIsProcessing] = useState(false);
  // const [refreshFiles, setRefreshFiles] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [refreshProcessed, setRefreshProcessed] = useState(false);

  useEffect(() => {
    const initializeAuth = async () => {
      try {
        // Check if the user is logged in
        const loggedIn = await isLoggedIn();
        // Set authentication state
        setAuthenticated(loggedIn);

        if (loggedIn) {
          localStorage.setItem("setAuthenticated", true);
        } else {
          localStorage.setItem("setAuthenticated", false);
        }

        // Allow rendering of the application
        setCanRender(true);
      } catch (error) {
        console.error("Error during authentication initialization:", error);
        // Allow rendering of the application even if there's an error
        setCanRender(true);
      }
    };

    initializeAuth();
  }, []);

  // Set up global error handling
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      // Check if it's a network error
      if (!error.response) {
        setErrorMessage("Network Error");
      } else if (error.message === "Refresh Token has expired") {
        // Check if it's a timeout error
        setErrorMessage("Timeout Error");
      } else {
        // Other errors
        setErrorMessage(error.response && error.response.data.message ? error.response.data.message : "An error occurred");
      }
      return Promise.reject(error);
    }
  );

  const closeModal = () => {
    setErrorMessage(null);
  };
  return canRender ? (
    <>
      <AuthContext.Provider value={{ authenticated, setAuthenticated }}>
        <ProjectContext.Provider
          value={{
            projectId,
            setProjectId,
            roles,
            setRoles,
            projectName,
            setProjectName,
            userId,
            setUserId,
            currentPage,
            setCurrentPage,
            refreshProcessed,
            setRefreshProcessed,
          }}
        >
          <Router>
            {authenticated ? (
              <Routes>
                <Route path="/" element={<AuthPage />}>
                  <Route path="change-password" element={<ChangePassword />} />
                </Route>
                <Route path="/*" element={<AuthenticatedRoutes />} />
              </Routes>
            ) : (
              <Routes>
                <Route path="/" element={<AuthPage />} />
                <Route path="/*" element={<AuthPage />} />
              </Routes>
            )}
          </Router>
        </ProjectContext.Provider>
      </AuthContext.Provider>
      <Modal show={!!errorMessage} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center text-danger fs-6">
          {errorMessage}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  ) : (
    <div>Loading...</div>
  );
}

export default App;

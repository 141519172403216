import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import axios from "axios";
import {
  getCurrentToken,
  getCurrentUser,
} from "../../services/cognito/cognitoAuth";

import { EyeFill, PencilSquare } from "react-bootstrap-icons";
import ProjectUsers from "./ProjectUsers";
import ProjectContext from "../../ProjectContext";
import { formatDate } from "../../utils";
import Pagination from "../Pagination";

function ProjectsList({ searchQuery, refreshKey, onEditProject }) {
  const [projects, setProjects] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPageSize, setTotalPageSize] = useState(1);
  const [show, setShow] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [selectedProjectName, setSelectedProjectName] = useState(null);
  const [cognitoSub, setCognitoSub] = useState(null);
  const [userId, setUserId] = useState(null);

  const [roles, setRoles] = useState([]);
  const [message, setMessage] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
  };
  const pageSize = 10;
  const { projectId, setProjectId, projectName, setProjectName } = useContext(ProjectContext);
  const [totalListCount, setTotalListCount] = useState(0);

  useEffect(() => {
    const fetchCognitoSub = async () => {
      try {
        const user = await getCurrentUser();
        setCognitoSub(user.sub);
      } catch (err) {
        console.error(err);
      }
    };
    fetchCognitoSub();
  }, []);
  useEffect(() => {
    if (cognitoSub) {
      const fetchUserID = async () => {
        try {
          const token = await getCurrentToken();

          if (!token) {
            console.error("No token found");
            return;
          }
          const userResponse = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}user?cognitoSub=${cognitoSub}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const userId = userResponse.data.id;
          setUserId(userId); // Update userId state
          const roles = userResponse.data.userGroups[0];
          setRoles(roles);

          const lastAccessedProjectId = userResponse.data.currentProject.id;
          setProjectId(lastAccessedProjectId);
        } catch (err) {
          console.log("Error in fetchUserID:", err);
        }
      };
      fetchUserID();
    }
  }, [cognitoSub]);

  useEffect(() => {
    if (userId) {
      fetchData();
    }
  }, [currentPage, searchQuery, refreshKey, userId, refresh]);
  const fetchData = async () => {
    try {
      const token = await getCurrentToken();

      if (!token) {
        console.error("No token found");
        return;
      }
      if (!userId) {
        console.log("User ID is not set");
        return;
      }
      const statusParam = roles.includes("Reviewer") ? "ACTIVE" : "";
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}project/list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            page: currentPage,
            size: pageSize,
            search: searchQuery,
            status: statusParam,
            sortColumn: "lastAccessedOn",
            sortOrder: "DESC",
            userId: userId,
          },
        }
      );

      const jsonData = response.data;
      const pageInfo = response.data.pageInfo;
      //const totalPages = Math.ceil(pageInfo.totalElements / pageInfo.size);
      if (pageInfo.totalElements > pageInfo.size) {
        setTotalPageSize(pageInfo.totalPageSize + 1);
      } else {
        setTotalPageSize(pageInfo.totalPageSize);
      }
      const totalList = response.data.pageInfo.totalElements;
      setTotalListCount(totalList);
      if (Array.isArray(jsonData.content)) {
        setProjects(jsonData.content);
      } else {
        console.error("Invalid data format: content is not an array");
      }
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };
  useEffect(() => {
  }, [projectId]);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getStatusClassName = (status) => {
    return status === "ACTIVE"
      ? "badge text-bg-success"
      : "badge text-bg-secondary";
  };
  const handleShowUsers = (projectId, projectName) => {
    setSelectedProjectId(projectId);
    setSelectedProjectName(projectName);
    handleShow();
  };
  const switchProject = async (projectId, projectName) => {
    try {
      const token = await getCurrentToken();

      if (!token) {
        console.error("No token found");
        return;
      }

      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}project/last-accessed-on`,
        {
          projectId,
          userId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Update the message state
      setMessage(`Switched to ${projectName}.`);
      // Hide the message after 5 seconds
      setTimeout(() => {
        setMessage(null);
      }, 5000);
      setRefresh((prevRefresh) => !prevRefresh);
      setProjectId(projectId);
      setProjectName(projectName);
    } catch (err) {
      console.log("Error in switchProject:", err);
    }
  };
  const handleProjectStatusChange = async (projectId, currentStatus) => {
    try {
      const token = await getCurrentToken();
  
      if (!token) {
        console.error("No token found");
        return;
      }
  
      const newStatus = currentStatus === "ACTIVE" ? "INACTIVE" : "ACTIVE";
  
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}project/status`,
        {
          projectId: projectId,
          status: newStatus,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      // Refresh the projects list after changing the status
      fetchData();
    } catch (err) {
      console.log("Error in handleProjectStatusChange:", err);
    }
  };
  return (
    <div className="table-responsive border rounded p-2">
      {message && (
        <div
          className="alert alert-success text-center fw-bold fs-6"
          role="alert"
        >
          {message}
        </div>
      )}
      {projects.length > 0 ? (
        <>
          <div className="table-responsive">
          <table className="table table-borderless">
            <thead>
              <tr>
                <th>Project Name</th>
                <th>Created At</th>
                <th>Status</th>
                {roles.includes("ProjectAdmin") && <th>Users</th>}
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {projects.map((project) => (
                <tr key={project.id}>
                  <td>
                    {project.name}
                    {project.id === projectId && (
                      <span className="badge text-bg-primary ms-2">
                        Current
                      </span>
                    )}
                  </td>
                  <td>
                    {project.createdAt ? formatDate(project.createdAt) : ""}
                  </td>
                  <td>
                    <span className={getStatusClassName(project.status)}>
                      {project.status}
                    </span>
                  </td>
                  {roles.includes("ProjectAdmin") && (
                    <td>
                      <Link
                        className="btn btn-sm btn-primary border-0 px-2 me-2"
                        onClick={() =>
                          handleShowUsers(project.id, project.name)
                        }
                      >
                        <EyeFill size={16} />
                      </Link>
                    </td>
                  )}
                  <td>
                    {roles.includes("ProjectAdmin") && (
                      <button
                        className="btn btn-sm btn-primary border-0 px-2 me-2"
                        onClick={() => onEditProject(project)} 
                        disabled={project.status === "INACTIVE"}
                      >
                        <PencilSquare size={16} />
                      </button>
                    )}
                    {roles.includes("ProjectAdmin") && (
                      <button
                        className={`btn btn-sm ${
                          project.status === "ACTIVE"
                            ? "btn-danger"
                            : "btn-success"
                        } px-3 me-2`}
                        onClick={() =>
                          handleProjectStatusChange(project.id, project.status)
                        }
                      >
                        {project.status === "ACTIVE"
                          ? "Deactivate"
                          : "Activate"}
                      </button>
                    )}
                    {project.status === "ACTIVE" && project.id !== projectId && (
                      <Link
                        className="btn btn-sm btn-warning border-0 px-2"
                        onClick={() => switchProject(project.id, project.name)}
                      >
                        Switch
                      </Link>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Modal show={show} onHide={handleClose}>
            <ProjectUsers
              projectId={selectedProjectId}
              projectName={selectedProjectName}
            />
          </Modal>
          </div>
          <Pagination
            currentPage={currentPage}
            totalPageSize={totalPageSize}
            onPageChange={handlePageChange}
            totalListCount={totalListCount}
            pageSize={pageSize}
          />
          
        </>
      ) : (
        <p className="text-center fs-5 mt-4">No projects to list.</p>
      )}
    </div>
  );
}

export default ProjectsList;
